import { Logger, LOG_SOURCE, getDaVinciAgentConfig, initializeComplete, IAppConfiguration } from '@amc-technology/davinci-api';
import { ILoggerConfiguration, defaultLoggerConfiguration } from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';
import { ConfigurationService } from './configuration-powerapps.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private _logger?: Logger;

  constructor(private configService: ConfigurationService) {}

  get logger() {
    if (!this._logger) {
      throw new Error('Logger is not initialized');
    }

    return this._logger;
  }

  async initialize() {
    try {
      // Get raw config. Framework does not perform data validation intentionally
      const rawConfig = await getDaVinciAgentConfig();
      // Perform data validation, revert to defaults if configs are invalid
      // or undefined
      let logLevel = parseInt(rawConfig?.variables?.['Log Level']?.toString(), 10);
      logLevel = isNaN(logLevel) ? defaultLoggerConfiguration['Log Level'] : logLevel;

      let maxLength = parseInt((rawConfig?.['Console Logger'] as IAppConfiguration)?.variables?.['Max Length']?.toString(), 10);
      maxLength = isNaN(maxLength) ? defaultLoggerConfiguration['Console Logger']['Max Length'] : maxLength;

      const loggerConfig: ILoggerConfiguration = {
        'Log Level': logLevel,
        'Logger Type': rawConfig?.variables?.['Logger Type']?.toString() || defaultLoggerConfiguration['Logger Type'],
        'Premise Logger URL': rawConfig?.variables?.['Premise Logger URL']?.toString() || defaultLoggerConfiguration['Premise Logger URL'],
        'Console Logger': {
          'Max Length': maxLength
        }
      };

      this._logger = new Logger(
        LOG_SOURCE.Other, // TODO - Change this to LOG_SOURCE.PowerApp when the framework is updated.
        false,
        this.configService.serverConfig.loggerApiUrl
      );

      this.logger.setConfiguration(loggerConfig);
    } catch (err) {
      this._logger = new Logger(
        LOG_SOURCE.Other, // TODO - Change this to LOG_SOURCE.PowerApp when the framework is updated.
        false,
        this.configService.serverConfig.loggerApiUrl
      );

      this.logger.logCritical('loggerService.initialize(): Error creating logger!');
    }
  }
}
