import { ConfigurationService } from '../_services/configuration-powerapps.service';
import { LoggerService } from '../_services/logger-powerapps.service';
import { PowerAppsService } from '../_services/powerapps.service';

export function initServicesFactory(configurationService: ConfigurationService, powerAppsService: PowerAppsService, loggerService: LoggerService) {
  return async () => {
    await configurationService.initialize();
    await loggerService.initialize();
    await powerAppsService.initialize();
  };
}
