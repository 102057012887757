/* eslint-disable @typescript-eslint/no-explicit-any */

import { getConfig } from '@amc-technology/davinci-api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IClientConfiguration } from '../_models/IClientConfiguration';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public config = {} as any;
  public serverConfig = {} as any;
  public appName = '';

  private baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async initialize(): Promise<void> {
    this.config = await getConfig();
    this.appName = this.config.name ?? 'DAVINCI APP FOR MICROSOFT POWER APPS';
    const serverConfig$ = this.http.get<IClientConfiguration>(`${this.baseUrl}ClientConfiguration`);
    this.serverConfig = await firstValueFrom(serverConfig$);
  }
}
