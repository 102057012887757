/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPowerApp } from './../_models/IPowerApp';
import { HttpClient } from '@angular/common/http';
import { SUPPORTED_EVENTS } from './../_models/SupportedEvents';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration-powerapps.service';
import { LoggerService } from './logger-powerapps.service';
import { IInteraction, INTERCEPTOR_TIMEOUT_ACTION, OPERATIONS, initializeComplete, registerOnLogout, registerOperationInterceptor, registerProcessInteraction, updateInteraction } from '@amc-technology/davinci-api';
import { IRequest, IResponse, isResponse } from '@amc-technology/davinci-api/dist/HelperFunctions';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { IFrameData } from '../_models/IFrameData';
import { ITrigger } from '../_models/ITrigger';
import { INTERACTION_STATES } from '@amc-technology/davinci-api';
import { TimerHandle } from 'rxjs/internal/scheduler/timerHandle';

@Injectable({
  providedIn: 'root'
})
export class PowerAppsService {
  private _config: any;
  private _timeout = 5000;
  private _timeoutAction = 'PROCEED_WITH_ORIGINAL';
  public changeIframe$ = new Observable<IFrameData>();
  private changeIframeSource: Subject<IFrameData> = new Subject<IFrameData>();
  public receivedResponse = false;
  public bc = new BroadcastChannel('DaVinciBroadcastChannel');
  private _eventMetadataFilter: any = {};
  private IPowerApp: IPowerApp = {} as IPowerApp;
  public scenarioInteractionMapping: string[][];
  public lastInteractionEventReceived: IInteraction;
  public automaticCloseoutTimeoutObject: TimerHandle | null;
  public frameLoaded;
  public appHasBeenTriggered;

  constructor(private configurationService: ConfigurationService, private loggerService: LoggerService, private _http: HttpClient) {
    this.changeIframe$ = this.changeIframeSource.asObservable();
    this.IPowerApp.trigger = {} as ITrigger;
    this.IPowerApp.powerAppUrl = '';
    this.IPowerApp.isSizeConfigurationValid = true;
    this.scenarioInteractionMapping = [];
    this.lastInteractionEventReceived = {} as IInteraction;
    this.automaticCloseoutTimeoutObject = null;
    this.frameLoaded = false;
    this.appHasBeenTriggered = false;
  }

  async initialize() {
    const FN = 'initialize';
    this.loggerService.logger.logInformation(`START - ${FN} - PowerAppsService`);
    try {
      this._config = this.configurationService.config;
      this._parseAndValidateConfig();
      await this._registerForAmcEvents();
      await initializeComplete();
      this.bc.onmessage = this.onBroadcastMessage.bind(this);
      this.bc.addEventListener('messageerror', (event) => {
        console.error(event);
      });

      this.loggerService.logger.logInformation(`END - ${FN} - PowerAppsService`);
    } catch (e) {
      this.loggerService.logger.logCritical('Error initializing PowerAppsService service');
    }
  }

  private _parseAndValidateConfig() {
    const FN = '_parseAndValidateConfig';
    this.loggerService.logger.logInformation(`START - ${FN}`);
    try {
      const newTrigger: ITrigger = {} as ITrigger;
      let triggerSelection = 0;

      if (!this._config?.variables || !this._config?.['Power App Configuration']?.variables) {
        throw new Error('Configuration is null or undefined');
      }

      if (this._config?.['Power App Configuration']?.variables?.['Async Processing'] === undefined) {
        this.loggerService.logger.logWarning('Async Processing is null or undefined. Using default value of false');
        this.IPowerApp.asyncProcessing = false;
      } else if (typeof this._config['Power App Configuration'].variables['Async Processing'] !== 'boolean') {
        this.loggerService.logger.logWarning('Async Processing configuration should be of type boolean. Using default value of false');
        this.IPowerApp.asyncProcessing = false;
      } else {
        this.IPowerApp.asyncProcessing = this._config['Power App Configuration'].variables['Async Processing'];
      }

      if (this._config?.['Power App Configuration']?.variables?.['Power App URL'] === undefined) {
        throw new Error('Power App URL is null or undefined');
      } else if (!this._isValidUrl(this._config['Power App Configuration'].variables['Power App URL'])) {
        throw new Error('Power App URL is invalid');
      } else {
        this.IPowerApp.powerAppUrl = this._config['Power App Configuration'].variables['Power App URL'].trim();
      }

      if (this._config?.['Power App Configuration']?.variables?.['Pop Out Power App'] === undefined) {
        this.loggerService.logger.logWarning('Pop Out Power App is null or undefined. Using default value of false');
        this.IPowerApp.popOutPowerApp = false;
      } else if (typeof this._config['Power App Configuration'].variables['Pop Out Power App'] !== 'boolean') {
        this.loggerService.logger.logWarning('Pop Out Power App configuration should be of type boolean. Using default value of false');
        this.IPowerApp.popOutPowerApp = false;
      } else {
        this.IPowerApp.popOutPowerApp = this._config['Power App Configuration'].variables['Pop Out Power App'];
      }

      if (this._config?.['Power App Configuration']?.variables?.['Power App Height'] === undefined) {
        this.loggerService.logger.logWarning('Power App Height is null or undefined. Using default value of 180px');
        if (this.IPowerApp.popOutPowerApp) {
          this.IPowerApp.isSizeConfigurationValid = false;
        } else {
          this.IPowerApp.powerAppHeight = 180;
        }
      } else if (typeof this._config['Power App Configuration'].variables['Power App Height'] !== 'number' || this._config['Power App Configuration'].variables['Power App Height'] < 0) {
        this.loggerService.logger.logWarning('Power App Height configuration should be of type number and should be greater than 0. Using default value of 180px');
        if (this.IPowerApp.popOutPowerApp) {
          this.IPowerApp.isSizeConfigurationValid = false;
        } else {
          this.IPowerApp.powerAppHeight = 180;
        }
      } else {
        this.IPowerApp.powerAppHeight = this._config['Power App Configuration'].variables['Power App Height'];
      }

      if (this._config?.['Power App Configuration']?.variables?.['Power App Width'] === undefined) {
        this.loggerService.logger.logWarning('Power App Width is null or undefined. Using default value of 180px');
        if (this.IPowerApp.popOutPowerApp) {
          this.IPowerApp.isSizeConfigurationValid = false;
        } else {
          this.IPowerApp.powerAppWidth = 180;
        }
      } else if (typeof this._config['Power App Configuration'].variables['Power App Width'] !== 'number' || this._config['Power App Configuration'].variables['Power App Width'] < 0) {
        this.loggerService.logger.logWarning('Power App Width configuration should be of type number and should be greater than 0. Using default value of 180px');
        if (this.IPowerApp.popOutPowerApp) {
          this.IPowerApp.isSizeConfigurationValid = false;
        } else {
          this.IPowerApp.powerAppWidth = 180;
        }
      } else {
        this.IPowerApp.powerAppWidth = this._config['Power App Configuration'].variables['Power App Width'];
      }

      const triggerNames = Object.keys(this._config?.['Power App Configuration']).filter((key) => key.startsWith('Trigger'));

      if (triggerNames.length === 0) {
        throw new Error('No triggers defined in configuration');
      } else if (triggerNames.length > 1) {
        this.loggerService.logger.logWarning('More than one trigger defined in configuration. The first enabled trigger that is found will be used');
      }

      for (let i = 0; i < triggerNames.length; i++) {
        if (this._config?.['Power App Configuration'][triggerNames[i]]?.variables?.Enabled === true) {
          triggerSelection = i;
          break;
        }
      }

      const triggerConfig = this._config?.['Power App Configuration'][triggerNames[triggerSelection]];

      if (triggerConfig?.variables?.Enabled === undefined) {
        throw new Error(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Enabled" variable`);
      } else if (triggerConfig.variables.Enabled === false) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} is disabled`);
      }

      if (triggerConfig?.variables?.['Trigger Event'] === undefined) {
        throw new Error(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Trigger Event" variable`);
      } else if (!SUPPORTED_EVENTS.has(triggerConfig.variables['Trigger Event'])) {
        throw new Error(`Trigger ${triggerNames[triggerSelection]} configuration has invalid or unsupported "Trigger Event" variable.
          Please refer documentation for supported events`);
      }

      if (triggerConfig?.variables?.['Timeout'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Timeout" variable. Using default value of ${this._timeout}`);
        newTrigger.timeout = this._timeout;
      } else if (typeof triggerConfig.variables['Timeout'] !== 'number' || triggerConfig.variables['Timeout'] < 0) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration has invalid "Timeout" variable. Using default value of ${this._timeout}`);
        newTrigger.timeout = this._timeout;
      } else {
        newTrigger.timeout = triggerConfig.variables['Timeout'];
      }

      if (triggerConfig?.variables?.['Timeout Action'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Timeout Action" variable. Using default value of ${this._timeoutAction}`);
        newTrigger.timeoutAction = this._timeoutAction;
      } else if (INTERCEPTOR_TIMEOUT_ACTION[triggerConfig.variables['Timeout Action'] as keyof typeof INTERCEPTOR_TIMEOUT_ACTION] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration has invalid "Timeout Action" variable. Using default value of ${this._timeoutAction}`);
        newTrigger.timeoutAction = this._timeoutAction;
      } else {
        newTrigger.timeoutAction = triggerConfig.variables['Timeout Action'];
      }

      if (triggerConfig?.variables?.['End of Scenario Delay'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "End of Scenario Delay" variable. Using default value of 5000`);
        newTrigger.endOfScenarioDelay = 5000;
      } else if (typeof triggerConfig.variables['End of Scenario Delay'] !== 'number' || triggerConfig.variables['End of Scenario Delay'] < 0) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration has invalid "End of Scenario Delay" variable. Using default value of 5000`);
        newTrigger.endOfScenarioDelay = 5000;
      } else {
        newTrigger.endOfScenarioDelay = triggerConfig.variables['End of Scenario Delay'];
      }

      if (triggerConfig?.variables?.['Event Filter'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Event Filter" variable.`);
      } else {
        newTrigger.eventFilter = triggerConfig.variables['Event Filter'];
      }

      if (triggerConfig.variables?.['Event Metadata Filter'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Event Metadata Filter" variable.`);
      } else {
        this._eventMetadataFilter = triggerConfig.variables['Event Metadata Filter'];
      }

      if (triggerConfig?.variables?.['Event Data to Send'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Event Data to Send" variable.`);
      } else {
        newTrigger.eventDataToSend = triggerConfig.variables['Event Data to Send'];
      }

      if (triggerConfig?.variables?.['Static Data to Send'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Static Data to Send" variable.`);
      } else {
        newTrigger.staticDataToSend = triggerConfig.variables['Static Data to Send'];
      }

      if (triggerConfig?.variables?.['Result Data Mapping'] === undefined) {
        this.loggerService.logger.logWarning(`Trigger ${triggerNames[triggerSelection]} configuration is missing "Result Data Mapping" variable.`);
      } else {
        newTrigger.resultDataMapping = triggerConfig.variables['Result Data Mapping'];
      }
      newTrigger.triggerEvent = triggerConfig.variables['Trigger Event'];
      this.IPowerApp.trigger = newTrigger;

      this.loggerService.logger.logInformation(`END - ${FN}`);
    } catch (e) {
      this.loggerService.logger.logCritical('Error parsing and validating configuration');
    }
  }

  private async _registerForAmcEvents() {
    const FN = '_registerForAmcEvents';
    this.loggerService.logger.logInformation(`START - ${FN}`);
    try {
      const operationsToIntercept = OPERATIONS[this.IPowerApp.trigger.triggerEvent as keyof typeof OPERATIONS];

      if (this.IPowerApp.asyncProcessing) {
        registerProcessInteraction(this._processOperation.bind(this));
      } else {
        registerOperationInterceptor([operationsToIntercept], this._interceptOperation.bind(this), this.IPowerApp.trigger.timeout, INTERCEPTOR_TIMEOUT_ACTION[this.IPowerApp.trigger.timeoutAction as keyof typeof INTERCEPTOR_TIMEOUT_ACTION]);
      }
      await registerOnLogout(this._onLogout.bind(this));
      this.loggerService.logger.logInformation(`END - ${FN}`);
    } catch (e) {
      this.loggerService.logger.logCritical('Error registering for AMC events');
    }
  }

  private checkFiltersAndBuildPayload(payload: any): any {
    const FN = 'checkFiltersAndBuildPayload';
    const appPayload: any = {};
    this.loggerService.logger.logInformation(`START - ${FN}`);

    try {
      for (const filter of Object.keys(this.IPowerApp.trigger.eventFilter)) {
        const filterProperties = filter.split('.');
        let tempData = payload;

        for (const property of filterProperties) {
          if (tempData[property] === undefined) {
            throw Error('The intercepted operation does not meet the event filter criteria');
          }
          tempData = tempData[property];
        }

        if (tempData.toString() !== this.IPowerApp.trigger.eventFilter[filter]) {
          throw Error('The intercepted operation does not meet the event filter criteria');
        }
      }

      if (!this.IPowerApp.asyncProcessing) {
        for (const filter of Object.keys(this._eventMetadataFilter)) {
          const filterProperties = filter.split('.');
          let tempData = payload;

          for (const property of filterProperties) {
            if (payload[property as keyof typeof payload] === undefined && payload.message[property as keyof typeof payload.message] === undefined) {
              throw Error(`Event metadata filter ${filter} not found in payload, ignoring event`);
            }
            tempData = tempData[property as keyof typeof payload];
          }

          if (tempData.toString() !== this._eventMetadataFilter[filter]) {
            throw Error(`Event metadata filter ${filter} does not match, ignoring event`);
          }
        }
      }

      for (const key of Object.keys(this.IPowerApp.trigger.eventDataToSend)) {
        const keyProperties = key.split('.');
        let tempData = payload;

        for (const property of keyProperties) {
          if (tempData[property] === undefined) {
            tempData = undefined;
            break;
          }
          tempData = tempData[property];
        }

        if (tempData !== undefined) {
          appPayload[this.IPowerApp.trigger.eventDataToSend[key]] = tempData;
        }
      }

      for (const key of Object.keys(this.IPowerApp.trigger.staticDataToSend)) {
        appPayload[key] = this.IPowerApp.trigger.staticDataToSend[key];
      }
    } catch (e) {
      this.loggerService.logger.logDebug(`Exiting FN: checkFiltersAndBuildPayload - Error: ${e}`);
      return false;
    }
    this.loggerService.logger.logInformation(`END - ${FN}`);
    return appPayload;
  }

  private async _interceptOperation(payload: { didTimeout?: boolean; pluginName: string; message: any }): Promise<{ pluginName: string; message: IRequest | IResponse }> {
    const FN = '_interceptOperation';
    this.loggerService.logger.logTrace(`START - ${FN}`);
    try {
      if (!isResponse(payload.message)) {
        const data = payload.message.data[0];

        if (this.scenarioInteractionMapping[data.scenarioId] === undefined) {
          this.scenarioInteractionMapping[data.scenarioId] = [];
          this.scenarioInteractionMapping[data.scenarioId].push(data.interactionId);
        }

        if (!this.appHasBeenTriggered && this.scenarioInteractionMapping[data.scenarioId] !== undefined && this.scenarioInteractionMapping[data.scenarioId].includes(data.interactionId)) {
          try {
            const appPayload = this.checkFiltersAndBuildPayload(data);
            if (appPayload !== false) {
              this.changeIframeSource.next({ state: 'init', showFrame: true, payload: JSON.stringify(appPayload) });
              this.appHasBeenTriggered = true;
              if (data.state === INTERACTION_STATES.Disconnected) {
                this.automaticCloseoutTimeoutObject = setTimeout(this.processDisconnectedInteraction.bind(this), this.IPowerApp.trigger.endOfScenarioDelay, data);
              }
            } else {
              this.loggerService.logger.logInformation('The configured event filters were not met. Exiting FN: _interceptOperation');
              return payload;
            }
          } catch (e) {
            this.loggerService.logger.logInformation('Exiting FN: _interceptOperation');
            return payload;
          }
        } else if (
          data.state !== INTERACTION_STATES.Disconnected &&
          this.appHasBeenTriggered &&
          this.scenarioInteractionMapping[data.scenarioId] !== undefined &&
          !this.scenarioInteractionMapping[data.scenarioId].includes(data.interactionId)
        ) {
          if (this.automaticCloseoutTimeoutObject) {
            clearTimeout(this.automaticCloseoutTimeoutObject);
            this.automaticCloseoutTimeoutObject = null;
          }
          this.scenarioInteractionMapping[data.scenarioId].pop();
          this.scenarioInteractionMapping[data.scenarioId].push(data.interactionId);
        } else {
          if (data.state === INTERACTION_STATES.Disconnected && this.scenarioInteractionMapping[data.scenarioId] !== undefined && this.scenarioInteractionMapping[data.scenarioId].includes(data.interactionId)) {
            this.automaticCloseoutTimeoutObject = setTimeout(this.processDisconnectedInteraction.bind(this), this.IPowerApp.trigger.endOfScenarioDelay, data);
          } else {
            this.loggerService.logger.logInformation('There is currently a power app open for a scenario. If another scenario attempts to trigger a power app nothing will happen.');
          }
        }
      }
      this.loggerService.logger.logTrace(`END - ${FN}`);
      return payload;
    } catch (e) {
      this.loggerService.logger.logError('Error intercepting operation');
      return payload;
    }
  }

  private async _processOperation(interaction: IInteraction): Promise<void> {
    const FN = '_processOperation';
    this.loggerService.logger.logTrace(`START - ${FN}`);
    try {
      const data = interaction as any;

      if (this.lastInteractionEventReceived !== undefined && this.lastInteractionEventReceived.interactionSequenceUpdateId !== undefined) {
        if (
          this.lastInteractionEventReceived.scenarioId === data.scenarioId &&
          this.lastInteractionEventReceived.interactionId === data.interactionId &&
          this.lastInteractionEventReceived.interactionSequenceUpdateId === data.interactionSequenceUpdateId - 1
        ) {
          this.loggerService.logger.logDebug(`This interaction event was raised from this application so we ignore it. Exiting FN: ${FN}`);
          this.lastInteractionEventReceived = interaction;
          return;
        }
      }

      if (this.scenarioInteractionMapping[data.scenarioId] === undefined) {
        this.scenarioInteractionMapping[data.scenarioId] = [];
        this.scenarioInteractionMapping[data.scenarioId].push(data.interactionId);
      }

      if (!this.appHasBeenTriggered && this.scenarioInteractionMapping[data.scenarioId] !== undefined && this.scenarioInteractionMapping[data.scenarioId].includes(data.interactionId)) {
        const appPayload = this.checkFiltersAndBuildPayload(data);
        if (appPayload !== false) {
          this.changeIframeSource.next({ state: 'init', showFrame: true, payload: JSON.stringify(appPayload) });
          this.appHasBeenTriggered = true;
          this.lastInteractionEventReceived = interaction;
          if (data.state === INTERACTION_STATES.Disconnected) {
            this.automaticCloseoutTimeoutObject = setTimeout(this.processDisconnectedInteraction.bind(this), this.IPowerApp.trigger.endOfScenarioDelay, data);
          }
        } else {
          this.loggerService.logger.logInformation(`The configured event filters were not met. Exiting: ${FN}`);
          return;
        }
      } else if (this.appHasBeenTriggered && this.frameLoaded && this.scenarioInteractionMapping[data.scenarioId] !== undefined && this.scenarioInteractionMapping[data.scenarioId].includes(data.interactionId)) {
        if (data.state === INTERACTION_STATES.Disconnected) {
          if (this.automaticCloseoutTimeoutObject === null) {
            this.automaticCloseoutTimeoutObject = setTimeout(this.processDisconnectedInteraction.bind(this), this.IPowerApp.trigger.endOfScenarioDelay, data);
          } else {
            this.loggerService.logger.logInformation('Closeout process has already started. Ignoring disconnected event');
          }
        }
        const appPayload = this.checkFiltersAndBuildPayload(data);
        this.lastInteractionEventReceived = interaction;
        if (appPayload !== false) {
          this.bc.postMessage(JSON.stringify(appPayload));
        } else {
          this.loggerService.logger.logInformation(`The configured event filters were not met. Exiting: ${FN}`);
          return;
        }
      } else if (data.state !== INTERACTION_STATES.Disconnected && this.scenarioInteractionMapping[data.scenarioId] !== undefined && !this.scenarioInteractionMapping[data.scenarioId].includes(data.interactionId)) {
        if (this.automaticCloseoutTimeoutObject) {
          clearTimeout(this.automaticCloseoutTimeoutObject);
          this.automaticCloseoutTimeoutObject = null;
        }

        this.scenarioInteractionMapping[data.scenarioId].pop();
        this.scenarioInteractionMapping[data.scenarioId].push(data.interactionId);
        this.lastInteractionEventReceived = interaction;
      } else {
        if (data.state === INTERACTION_STATES.Disconnected && this.scenarioInteractionMapping[data.scenarioId] === undefined) {
          this.loggerService.logger.logInformation('Received a disconnected event but there is not an active scenario. Ignoring event');
        } else {
          this.loggerService.logger.logInformation('There is currently a power app open for a scenario. If another scenario attempts to trigger a power app nothing will happen.');
        }
      }
      this.loggerService.logger.logTrace(`END - ${FN}`);
      return;
    } catch (e) {
      this.loggerService.logger.logError(`Error while processing operation: ${e}`);
    }
  }

  private processDisconnectedInteraction(data: any) {
    const FN = 'processDisconnectedInteraction';
    this.loggerService.logger.logInformation(`START - ${FN}`);
    try {
      if (Object.keys(this.scenarioInteractionMapping[data.scenarioId]).length !== 0) {
        this.scenarioInteractionMapping[data.scenarioId].pop();
      }
      if (this.scenarioInteractionMapping[data.scenarioId] && Object.keys(this.scenarioInteractionMapping[data.scenarioId]).length === 0) {
        delete this.scenarioInteractionMapping[data.scenarioId];
        this.lastInteractionEventReceived = {} as IInteraction;
        this.automaticCloseoutTimeoutObject = null;
        if (this.frameLoaded === true) {
          this.changeIframeSource.next({ state: 'complete', showFrame: false });
        }
        this.appHasBeenTriggered = false;
      } else {
        this.loggerService.logger.logInformation('This Scenario still has interactions, the scenario will continue to be tracked.');
      }
      this.loggerService.logger.logInformation(`END - ${FN}`);
    } catch (err) {
      this.loggerService.logger.logError('Error processing disconnected interaction');
    }
  }

  pushNewProcessorEvent(returnData: any) {
    const FN = 'pushNewProcessorEvent';
    this.loggerService.logger.logInformation(`START - ${FN}`);
    try {
      if (this.IPowerApp.trigger.resultDataMapping === undefined) {
        this.loggerService.logger.logWarning('Result Data Mapping is not defined');
        return;
      } else if (this.IPowerApp.trigger.resultDataMapping === '') {
        this.loggerService.logger.logWarning('Result Data Mapping is empty');
        return;
      } else {
        for (const key of Object.keys(this.IPowerApp.trigger.resultDataMapping)) {
          const keyProperties = key.split('.');
          let tempData = returnData;

          for (const property of keyProperties) {
            if (tempData[property] === undefined) {
              tempData = undefined;
              break;
            }

            tempData = tempData[property];
          }
          if (this.lastInteractionEventReceived.details && this.lastInteractionEventReceived.details.fields) {
            this.lastInteractionEventReceived.details.fields[this.IPowerApp.trigger.resultDataMapping[key]] = tempData;
          }
        }
        updateInteraction(this.lastInteractionEventReceived);
      }
      this.loggerService.logger.logInformation(`END - ${FN}`);
    } catch (e) {
      this.loggerService.logger.logError('Error pushing new processor event');
    }
  }

  private onBroadcastMessage(event: MessageEvent) {
    const FN = 'onBroadcastMessage';
    this.loggerService.logger.logInformation(`START - ${FN}`);
    try {
      this.loggerService.logger.logDebug(`Received broadcast message: ${JSON.stringify(event.data)}`);

      if (event.data.type === 'initComplete') {
        this.loggerService.logger.logInformation('Received initComplete message, this function ignores this and is instead handled adhoc in the waitForIframeToLoad function');
      } else {
        const powerAppsEventData = JSON.parse(event.data.CAD);
        const ternResult =
          powerAppsEventData !== undefined && this.IPowerApp.asyncProcessing === true
            ? this.pushNewProcessorEvent(powerAppsEventData)
            : this.loggerService.logger.logInformation('PowerAppsEventData is undefined, a new processor event will not be raised.');
      }
      this.loggerService.logger.logInformation(`END - ${FN}`);
    } catch (e) {
      this.loggerService.logger.logError(`Error processing broadcast message: ${JSON.stringify(event.data)}`);
    }
  }

  private async _onLogout() {
    const FN = '_onLogout';
    this.loggerService.logger.logInformation(`START - ${FN}`);
    try {
      this.loggerService.logger.logDebug('Flushing logs before logout');
      await this.loggerService.logger?.pushLogsAsync();
    } catch (e) {
      this.loggerService.logger.logError('Error flushing logs before logout');
    }
  }

  private _isValidUrl(url: string): boolean {
    const urlRegex = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
    return urlRegex.test(url);
  }

  get powerAppUrl(): string {
    return this.IPowerApp.powerAppUrl;
  }

  get popOutPowerApp(): boolean {
    return this.IPowerApp.popOutPowerApp;
  }

  get powerAppHeight(): number {
    return this.IPowerApp.powerAppHeight;
  }

  get powerAppWidth(): number {
    return this.IPowerApp.powerAppWidth;
  }

  get isSizeConfigurationValid(): boolean {
    return this.IPowerApp.isSizeConfigurationValid;
  }
}
