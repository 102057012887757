import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { initServicesFactory } from './_factoryFunctions/init-services-factory';
import { HomeComponent } from './_components/home/home-powerapps.component';
import { ConfigurationService } from './_services/configuration-powerapps.service';
import { LoggerService } from './_services/logger-powerapps.service';
import { PowerAppsService } from './_services/powerapps.service';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }), HttpClientModule, FormsModule, RouterModule.forRoot([{ path: '', component: HomeComponent, pathMatch: 'full' }])],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initServicesFactory,
      deps: [ConfigurationService, PowerAppsService, LoggerService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
