import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { initializeComplete, setAppHeight } from '@amc-technology/davinci-api';
import { LoggerService } from 'src/app/_services/logger-powerapps.service';
import { PowerAppsService } from 'src/app/_services/powerapps.service';
import { IFrameData } from 'src/app/_models/IFrameData';

@Component({
  selector: 'app-home-powerapps',
  templateUrl: './home-powerapps.component.html',
  styleUrls: ['./home-powerapps.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('appContent', { static: true }) appContent: ElementRef | undefined;
  @ViewChild('iframe', { static: true }) iframe: ElementRef | undefined;

  private powerAppWindow: Window | undefined;

  constructor(private powerAppsService: PowerAppsService, private loggerService: LoggerService, private renderer: Renderer2) {}

  async ngOnInit(): Promise<void> {
    try {
      this.loggerService.logger.logDebug('Initializing home component');
      this.initialize();
      this.resetAppHeight();
      this.setupAppHeightObserver();
      await initializeComplete();

      this.loggerService.logger.logDebug('Finished initializing home component');
    } catch (e) {
      this.loggerService.logger.logCritical('Error initializing home component');
    }
  }

  private initialize() {
    try {
      this.loggerService.logger.logDebug('Subscribing to receive power app frame updates');

      this.powerAppsService.changeIframe$.subscribe({
        next: (frameData) => this.changeIframe(frameData)
      });
    } catch (e) {
      this.loggerService.logger.logCritical('Error initializing variables');
    }
  }

  private async changeIframe(frameData: IFrameData) {
    try {
      this.loggerService.logger.logDebug(`Changing iframe to ${JSON.stringify(frameData)}`);

      if (this.powerAppsService.popOutPowerApp) {
        if (frameData.showFrame && (!this.powerAppWindow || this.powerAppWindow?.closed)) {
          if (this.powerAppsService.isSizeConfigurationValid) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.powerAppWindow = window.open(this.powerAppsService.powerAppUrl, '', `height=${this.powerAppsService.powerAppHeight}px, width=${this.powerAppsService.powerAppWidth}px`)!;
          } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.powerAppWindow = window.open(this.powerAppsService.powerAppUrl, '')!;
          }
          await this.waitForIframeToLoad(this.powerAppsService.bc, 'message', 'message');
          this.powerAppsService.frameLoaded = true;
        } else {
          this.powerAppsService.frameLoaded = false;
          this.powerAppWindow?.close();
        }
        this.powerAppsService.bc.postMessage(frameData.payload);
      } else {
        if (frameData.showFrame) {
          this.renderer.setAttribute(this.iframe?.nativeElement, 'src', this.powerAppsService.powerAppUrl);
          this.renderer.setStyle(this.appContent?.nativeElement, 'display', 'block');
          this.renderer.setStyle(this.appContent?.nativeElement, 'height', `${this.powerAppsService.powerAppHeight}px`);
          this.renderer.setStyle(this.appContent?.nativeElement, 'width', `${this.powerAppsService.powerAppWidth}px`);
          await this.waitForIframeToLoad(this.powerAppsService.bc, 'message', 'message');
          this.powerAppsService.frameLoaded = true;
        } else {
          this.renderer.setAttribute(this.iframe?.nativeElement, 'src', '');
          this.renderer.setStyle(this.appContent?.nativeElement, 'display', 'none');
          this.powerAppsService.frameLoaded = false;
        }
        this.powerAppsService.bc.postMessage(frameData.payload);
      }
    } catch (e) {
      this.loggerService.logger.logError(`Error changing iframe to ${JSON.stringify(frameData)}`);
    }
  }

  private waitForIframeToLoad(eventTarget: EventTarget, eventName: string, eventType: string): Promise<boolean> {
    try {
      this.loggerService.logger.logDebug('Waiting for iframe to load');

      return new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handler = (event: any) => {
          if (event.type === eventType && event.data.type === 'initComplete') {
            eventTarget.removeEventListener(eventName, handler);
            resolve(true);
          }
        };
        eventTarget.addEventListener(eventName, handler);
      });
    } catch (e) {
      this.loggerService.logger.logError('Error waiting for iframe to load');
      return Promise.resolve(false);
    }
  }

  private resetAppHeight() {
    try {
      this.loggerService.logger.logDebug('Resetting app height to 0px');
      setAppHeight(0);
    } catch (e) {
      this.loggerService.logger.logError('Error resetting app height to 0px');
    }
  }

  private setupAppHeightObserver() {
    try {
      this.loggerService.logger.logDebug('Setting up app height observer');

      if (this.appContent) {
        const resizeObserver = new ResizeObserver((entries) => {
          setAppHeight(entries[0].contentRect.height + 10);
        });

        resizeObserver.observe(this.appContent.nativeElement);
      }
    } catch (e) {
      this.loggerService.logger.logError('Error setting up app height observer');
    }
  }
}
